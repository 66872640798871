import ApiService from './ApiService';
import appConfig from 'configs/app.config';

export async function apiSignIn(data) {
    const API_URL = appConfig.apiPrefix;
    return ApiService.fetchData({
        url: `/client/login/`,
        method: 'post',
        data: {
            email: data.userName,
            password: data.password,
        },
    });
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/client/register',
        method: 'post',
        data,
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/client/signout',
        method: 'get',
        data,
    });
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/client/forget-password/',
        method: 'post',
        data,
    });
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    });
}
