import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import './locales';
import { apiGetCurrentClientData } from 'services/ClientService';
import { PostHogProvider } from 'posthog-js/react';

// import ReactGA from 'react-ga';

// const TRACKING_ID = 'UA-164593545-1'; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

//const environment = process.env.NODE_ENV

const posthogKey = 'phc_iwsuhGOZBdyxpF7jgPvLt6L4WFKOtvAnAE9bZYvdujc';
const posthogHost = 'https://us.i.posthog.com';

function App() {
    const [username, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        apiGetCurrentClientData().then((res) => {
            setUserName(`${res.data?.firstName} ${res.data?.lastName}`);
            setUserEmail(res.data?.email);
        });
    }, []);

    const intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'heia3gf7',
        name: username || 'Visitor',
    };

    if (userEmail) {
        intercomSettings.email = userEmail;
    }

    window.intercomSettings = intercomSettings;
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/heia3gf7'
    (function () {
        let w = window;
        let ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            let d = document;
            let i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            let l = function () {
                let s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/heia3gf7';
                let x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();

    return (
        <PostHogProvider
         apiKey={posthogKey}
         options={{
             api_host: posthogHost,
         }}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <Layout />
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </PostHogProvider>
    );
}

export default App;
